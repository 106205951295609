// @flow
import { bindActionCreators } from 'redux';

import { toggleLeftDrawer, togglePopup, toggleRightDrawer } from '../../../redux/actions';

export default function Layout(dispatch: any) {
  return {
    // @ts-ignore
    toggleLeftDrawer: bindActionCreators(toggleLeftDrawer, dispatch),
    // @ts-ignore
    toggleRightDrawer: bindActionCreators(toggleRightDrawer, dispatch),
    // @ts-ignore
    togglePopup: bindActionCreators(togglePopup, dispatch),
  };
}
