// @flow
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import globalStyles from './global-styles';
import { extendProps } from './hoc';
import defaultDispatchers from '../redux/default-dispatchers';

/**
 * wrapper for Redux, NextJS/Routing, MaterialUI/Styles etc.
 */
export default function componentConnect({
  styles = {},
  dispatchers,
  state,
  formConfig,
  initialValues,
}: any = {}): any {
  const initProps = (redux: any, props: any): any => {
    if (typeof state === 'function' && typeof initialValues === 'function') {
      return {
        redux: state(redux),
        ...props,
        initialValues: initialValues(redux, props),
      };
    }
    if (typeof state === 'function') {
      return {
        redux: state(redux),
        ...props,
      };
    }
    if (typeof initialValues === 'function') {
      return {
        redux,
        ...props,
        initialValues: initialValues(redux, props),
      };
    }
    return {
      redux,
      ...props,
    };
  };
  const getDispatchers = (dispatch: any): any => {
    const methods = defaultDispatchers(dispatch);

    if (typeof dispatchers === 'function') {
      return { ...dispatchers(dispatch), ...defaultDispatchers(dispatch) };
    }

    return methods;
  };

  return function (component: any): any {
    if (formConfig) {
      return withStyles(
        t => {
          const s = typeof styles === 'function' ? styles(t) : styles;
          return {
            ...globalStyles(t),
            ...s,
          };
          // @ts-ignore
        },
        { withTheme: true }
      )(connect(initProps, getDispatchers)(reduxForm(formConfig)(withRouter(extendProps(component)))));
    }
    return withStyles(
      t => {
        const s = typeof styles === 'function' ? styles(t) : styles;

        return {
          ...globalStyles(t),
          ...s,
        };
        // @ts-ignore
      },
      { withTheme: true }
    )(connect(initProps, getDispatchers)(withRouter(extendProps(component))));
  };
}
