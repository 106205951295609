import { Button, CardActions, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

import dispatchers from './dispatchers';
import connect from '../../../lib/connect';

interface Props extends GlobalProps {
  message: any;
  togglePopup: any;
}

const styles = (theme: any): any => ({
  card: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 345,
    margin: 0,
    padding: 0,
  },
  cardContent: {
    width: `calc(100% - ${parseInt(theme.spacing(2))}px)`,
    maxWidth: 345,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  cardActions: {
    width: `calc(100% - ${parseInt(theme.spacing(1))}px)`,
    maxWidth: 345,
    display: 'block',
    padding: theme.spacing(1),
  },
  whiteSpace: {
    whiteSpace: 'normal',
    lineHeight: '1.2em',
    paddingBottom: theme.spacing(1) / 2,
    '&:last-child': { paddingBottom: 0 },
  },
  right: { float: 'right' },
});

const notification = (props: Props): any => {
  const { classes, message, router } = props;
  const { state } = props.redux;
  const removeNotification = msg => async () => {
    if (state.notifications.length === 1) {
      await props.togglePopup({ anchorEl: null, openPopup: '' });
    }

    await props.removeNotification(msg);
  };
  const read = payload => async () => {
    router.push({
      pathname: '/customer-support/tickets/view',
      query: {
        ticketId: payload.ticketId,
        limit: 50,
      },
    });

    await props.togglePopup({ anchorEl: null, openPopup: '' });
    await props.removeNotification(payload);
  };

  const text = message.label || message.description;

  return (
    <div className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.whiteSpace} gutterBottom variant="body1" component="p">
          from: {message.from.length > 125 ? `${message.from.substr(0, 125)}...` : message.from}
        </Typography>
        <Typography className={classes.whiteSpace} variant="body1" component="p">
          {text.length > 125 ? `${text.substr(0, 125)}...` : text}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button size="small" color="primary" onClick={read(message)}>
          read
        </Button>
        <Button size="small" color="primary" onClick={removeNotification(message)}>
          close
        </Button>
        {message.timestamp && (
          <Button size="small" className={classes.right} color="secondary" disabled>
            {moment(message.timestamp).calendar()}
          </Button>
        )}
      </CardActions>
    </div>
  );
};

export default connect({ styles, dispatchers })(notification);
