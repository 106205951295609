import api from './api-client';

/**
 * one field validators for redux-form
 */
export const required = (value = ''): boolean | string => {
  return !value || value === '-' ? 'required' : false;
};
export const email = (value = ''): boolean | string => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'invalid email address' : false;
};
export const stringNoWhiteSpace = (value = ''): boolean | string => {
  return value && (value.length < 5 || !/^\S+$/g.test(value)) ? 'no white space, min 5 characters' : false;
};
export const minLength6 = (value = ''): boolean | string => {
  return value && value.length < 6 ? 'must be 6 characters or less' : false;
};
export const minLength4 = (value = ''): boolean | string => {
  return value && value.length !== 4 ? 'must be 4 characters' : false;
};
export const maxLength1500 = (value = ''): boolean | string => {
  return value && String(value).length > 1500 ? 'must be less then 1500 characters' : false;
};
export const exactLength11 = (value = ''): boolean | string => {
  return value && String(value).length === 11 ? 'must be 11 characters' : false;
};
export const positiveNumber = (value = ''): boolean | string => {
  return value && Number(value) < 0 ? 'must be grater or equal to 0' : false;
};
export const phoneNumber = (value = ''): boolean | string => {
  return value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'invalid phone number, must be 10 digits' : false;
};
export const domain = (value = ''): boolean | string => {
  if (value.includes('www.')) {
    return 'invalid domain, remove [www]';
  }

  const isValid = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value);

  return isValid ? false : 'domain is invalid';
};
export const domainNotRequired = (value = ''): boolean | string => {
  if (!value) {
    return false;
  }

  if (value.includes('www.')) {
    return 'invalid domain, remove [www]';
  }

  const isValid = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value);

  return isValid ? false : 'domain is invalid';
};

export const asyncValidateEmail = (values: any) => {
  return api.rest
    .service('v1/validate/email')
    .create({ email: values.email })
    .then(res => {
      if (!res.result) {
        throw { email: res.error };
      }
    });
};
export const asyncValidateDomain = async (values: any) => {
  let adminDomain: any = {};
  const [domainResult, stagingDomainResult] = await Promise.all([
    api.rest.service('v1/validate/domain').create({ domain: values.domain }),
    api.rest.service('v1/validate/domain').create({ domain: values.stagingDomain }),
  ]);

  if (values.adminDomain) {
    adminDomain = await api.rest.service('v1/validate/domain').create({ domain: values.adminDomain });
  }

  const errors: any = {};

  if (!domainResult.result) errors.domain = domainResult.error;
  if (!stagingDomainResult.result) errors.stagingDomain = stagingDomainResult.error;
  if (values.adminDomain && !adminDomain.result) errors.adminDomain = adminDomain.error;

  return errors;
};
