import {
  Checkbox as CheckboxOg,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField as TextFieldOg,
} from '@mui/material';

export const TextField = ({
  label,
  input,
  meta: { touched, invalid, error } = {
    touched: undefined,
    invalid: undefined,
    error: undefined,
  },
  ...custom
}: any) => (
  <TextFieldOg
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export const Checkbox = ({ input, label }: any) => (
  <div>
    <FormControlLabel control={<CheckboxOg checked={!!input.value} onChange={input.onChange} />} label={label} />
  </div>
);

export const Button = ({ input, ...rest }: any) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="female" control={<Radio />} label="Female" />
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="other" control={<Radio />} label="Other" />
    </RadioGroup>
  </FormControl>
);

export const SelectField = ({
  input,
  label,
  meta: { touched, error } = { touched: undefined, error: undefined },
  children,
  ...custom
}: any) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor="age-native-simple">Age</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'age',
        id: 'age-native-simple',
      }}
    >
      {children}
    </Select>
    {touched && error && <FormHelperText>{touched && error}</FormHelperText>}
  </FormControl>
);
