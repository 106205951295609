import { Close } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import React from 'react';

import connect from '../../../lib/connect';

interface Props extends GlobalProps {}

const styles = (theme: any): any => ({
  snackbar: { top: 0 },
  close: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 0,
  },
});

const snackbar = (props: Props): any => {
  const { classes } = props;
  const { state } = props.redux;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(state.snackMsg)}
      message={<span>{state.snackMsg}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => props.toggleSnack('')}
          size="small"
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
};

export default connect({ styles })(snackbar);
