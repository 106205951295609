import { LoadingButton } from '@mui/lab';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

const styles = (theme: any): any => ({
  asyncBtnWrapper: {
    maxWidth: '100%',
    position: 'relative',
    marginRight: theme.spacing(1),
    '&:last-child': { marginRight: 0 },
  },
  asyncBtn: {
    width: '100%',
  },
});

const loadingButton = (props: any): any => {
  const { classes, loading, label, width, height, size = 'small' } = props;
  return (
    <div
      className={classNames(classes.asyncBtnWrapper, props.className || classes.asyncBtnWrapper)}
      style={{ width, height }}
    >
      <LoadingButton
        className={classes.asyncBtn}
        variant={props.variant}
        size={size}
        color={props.color}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type || 'button'}
        loading={loading}
      >
        {label}
      </LoadingButton>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(loadingButton) as any;
