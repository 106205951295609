import { Badge, Tooltip } from '@mui/material';
import React from 'react';
import shortid from 'shortid';

const CustomBadge = ({ badgeContent, children, ...props }) => {
  if (!badgeContent || badgeContent === '*' || badgeContent < 100) {
    return (
      <Badge badgeContent={badgeContent === '*' ? '' : badgeContent} overlap="rectangular" {...props}>
        {children}
      </Badge>
    );
  }
  return (
    <Tooltip id={shortid.generate()} title={badgeContent || '0'}>
      <Badge badgeContent="99+" {...props}>
        {children}
      </Badge>
    </Tooltip>
  );
};

export default CustomBadge;
