import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';

import connect from '../../../lib/connect';
import { TextField } from '../../../lib/redux-form-material-ui';
import { required } from '../../../lib/validator';
import LoadingButton from '../LoadingButton';

interface Props extends GlobalProps {
  open: boolean;
  onClose: any;
}

interface State {
  selectedImg: any;
}

const styles = (): any => ({
  textField: {
    width: '100%',
    minHeight: 40,
    marginRight: 0,
  },
  textAreaField: {
    width: '100%',
    minHeight: 45,
    marginRight: 0,
  },
});

class Element extends Component<Props, State> {
  submit = async values => {
    const { logger, toggleSnack, isLoading, api, reset, onClose } = this.props;

    await isLoading(true);
    try {
      await api.service('v1/controllers/feedback').create(values);
      toggleSnack('Thank you, your request has been sent!');
      reset();
      onClose();
    } catch (err: any) {
      logger.error(err);
      toggleSnack(err.message);
    }
    await isLoading(false);
  };

  render() {
    const { submitting, pristine, classes, onClose, open, handleSubmit, redux } = this.props;
    const subject = redux?.form?.helpAndFeedback?.values?.subject;

    return (
      <Fragment>
        {open && (
          <Dialog onClose={onClose} open={open}>
            <DialogContent>
              <Field
                className={classes.textField}
                component={TextField}
                SelectProps={{ native: true }}
                label="Subject"
                name="subject"
                margin="none"
                variant="outlined"
                validate={[required]}
                select
              >
                <option />
                <option value="bug">bug report</option>
                <option value="general">general question</option>
                <option value="new-feature">new feature request</option>
              </Field>
              {subject === 'new-feature' ? (
                <a href="https://kentro.io/feedback" target="_blank" rel="noopener noreferrer">
                  Please use feedback section, to be able to track progress of the request!
                </a>
              ) : (
                <Field
                  className={classes.textAreaField}
                  component={TextField}
                  label="Message"
                  name="message"
                  type="text"
                  margin="none"
                  variant="outlined"
                  validate={[required]}
                  rows={5}
                  multiline
                />
              )}
            </DialogContent>
            <DialogActions>
              {subject !== 'new-feature' && (
                <LoadingButton
                  className={classNames(classes.loadingBtn, classes.noMargin, classes.marginLeft2)}
                  color="primary"
                  onClick={handleSubmit(this.submit)}
                  disabled={pristine || submitting || redux.state.loading}
                  loading={submitting || redux.state.loading}
                  label="SEND"
                />
              )}
              <Button size="small" color="secondary" onClick={onClose}>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Fragment>
    );
  }
}

export default connect({
  styles,
  formConfig: { form: 'helpAndFeedback' },
})(Element);
