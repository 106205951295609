// @flow
import { bindActionCreators } from 'redux';

import { toggleLeftDrawer, togglePopup, toggleRightDrawer } from '../../../redux/actions';

export default function Header(dispatch: any) {
  return {
    toggleLeftDrawer: bindActionCreators(toggleLeftDrawer, dispatch),
    toggleRightDrawer: bindActionCreators(toggleRightDrawer, dispatch),
    togglePopup: bindActionCreators(togglePopup, dispatch),
  };
}
